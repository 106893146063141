<template>
    <div>
        <a-card v-for="(step, index) in steps" :key="index" v-show="currentStep === index"
            class="card-audit header-solid h-full" :bodyStyle="{ paddingTop: 0 }">
            <div class="card-header">
                <ul class="steps">
                    <li v-for="(step, index) in steps" :key="index" class="step" :class="{ active: currentStep === index }">
                        Step {{ index + 1 }}
                    </li>
                </ul>
            </div>
            <step1 v-if="currentStep === 0" @updateData="updateStep1Data"></step1>
            <step2 v-if="currentStep === 1" @updateData2="updateStep2Data"></step2>
            <step3 v-if="currentStep === 2"></step3>

            <div class="card-footer">
                <div class="flex justify-between">
                    <!-- <div class="button-left">
                        <a-button block size="small" type="danger" @click="previousStep" :disabled="currentStep === 0">
                            <span class="text-black">Previous</span>
                        </a-button>
                    </div> -->
                    <div class="button-right">
                        <a-button block size="small" type="primary" @click="nextStep"
                            v-if="currentStep !== steps.length - 1">
                            Next
                        </a-button>
                    </div>

                </div>
            </div>
        </a-card>


    </div>
</template>

<script>
import Step1 from './Step1.vue'
import Step2 from './Step2.vue';
import Step3 from './Step3.vue';
import _ from 'lodash';

export default {
    name: 'MultiStepForm',
    components: {
        Step1,
        Step2,
        Step3,
    },
    data() {
        return {
            currentStep: 0,
            steps: [
                Step1,
                Step2,
                Step3,
            ],
            minimumAmount: 50000,
            // minimumAmount: 1,
            step1Data: {},
            step2Data: {},
            data: {},
            serviceApi: ''
        };
    },
    mounted() {
        this.serviceApi = process.env.VUE_APP_SERVICE_API
    },
    created() {
        this.serviceApi = process.env.VUE_APP_SERVICE_API
    },
    methods: {
        updateStep1Data(data) {
            // validation 
            if (data.name_token === '') {
                this.$notification.error({
                    message: 'Error',
                    description: 'Please input your Name Token !',
                });
                return;
            }
            if (data.symbol === '') {
                this.$notification.error({
                    message: 'Error',
                    description: 'Please input your Symbol !',
                });
                return;
            }
            if (data.ca === '') {
                this.$notification.error({
                    message: 'Error',
                    description: 'Please input your Contract Address (CA) !',
                });
                return;
            }
            this.step1Data = data;
            console.log('dataChild1', data);
        },
        updateStep2Data(data) {
            console.log('dataChild2', data);
            // validation 
            if (data.smart_contract === '') {
                this.$notification.error({
                    message: 'Error',
                    description: 'Please input your Smart Contract !',
                });
                return;
            }
            this.step2Data = data;
            // convert object to string this.step2Data.smart_contract
            this.step2Data.smart_contract = JSON.stringify(this.step2Data.smart_contract);
            console.log('dataChild2', this.step2Data.smart_contract);
            this.data = _.merge(this.step1Data, this.step2Data);
            console.log('data', this.data);
            this.$axios.post(`${this.serviceApi}/api/audit`, this.data).then((res) => {
                console.log('res', res);
                if (res.data.status === 200) {
                    this.$notification.success({
                        message: 'Success',
                        description: 'Audit success !',
                    });
                } else {
                    this.$notification.error({
                        message: 'Error',
                        description: 'Audit failed !',
                    });
                }
            }).catch((err) => {
                console.log('err', err);
                this.$swal({
                    title: 'Error',
                    text: `Audit failed ${err} ! `,
                    icon: 'error',
                    confirmButtonText: 'OK',
                });
            });

        },
        nextStep() {
            console.log('nextStep');
            const balanceAddress = localStorage.getItem('balanceAddress');
            if (balanceAddress < this.minimumAmount) {
                this.$notification.error({
                    message: 'Error',
                    description: 'Your balance is less than 50.000',
                });
                return;
            }
            const isObjectEmpty = Object.keys(this.step1Data).length === 0 && this.step1Data.constructor === Object;
            if (isObjectEmpty) {
                this.$notification.error({
                    message: 'Error',
                    description: 'Please fill in the information',
                });
                return;
            }


            if (this.currentStep < this.steps.length - 1) {
                this.currentStep++;
            }
        },
        previousStep() {
            if (this.currentStep > 0) {
                this.currentStep--;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
$number-of-steps: 3;

$line-width: 2px;
$bullet-size: 2em;

$background-color: #050816;
$foreground-color: #555;

$active-background-color: #1ab8bc;
$active-foreground-color: #fff;

.button-left {
    float: left;
}

.button-right {
    float: right;
}


.steps {
    margin: 0 auto;
    padding: 0;
    width: 100%;
    list-style: none;
    counter-reset: wizard;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1em;
    margin-top: 1em;
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;


    li {
        position: relative;
        float: left;
        width: 100% / $number-of-steps;
        text-align: center;
        color: $active-background-color;
        font-size: 0.8em;
        text-transform: uppercase;
        font-weight: bold;
        padding: 0.5em 0;
        list-style: none;
    }

    .active~li {
        color: $foreground-color;
    }

    li:before {
        counter-increment: wizard;
        content: counter(wizard);
        display: block;
        color: $active-foreground-color;
        background-color: $active-background-color;
        border: $line-width solid $active-background-color;
        text-align: center;
        width: $bullet-size;
        height: $bullet-size;
        line-height: $bullet-size;
        border-radius: $bullet-size;
        position: relative;
        left: 50%;
        margin-bottom: $bullet-size / 2;
        margin-left: $bullet-size * -0.5;
        z-index: 1;

        .active~& {
            background-color: $background-color;
            color: $foreground-color;
            border-color: $foreground-color;
        }
    }

    li+li {
        &:after {
            content: "";
            display: block;
            width: 100%;
            background-color: $active-background-color;
            height: $line-width;
            position: absolute;
            left: -50%;
            top: $bullet-size / 2;
            z-index: 0;
        }
    }

    .active~li:after {
        background-color: $foreground-color;
    }

    .active~h1::after {
        background-color: $foreground-color;
    }


    .text-black {
        color: black !important;
    }
}
</style>
