<template>
    <div>
        <div class="text-center">
            <h6>Information Token</h6>
            <p class="text-center my-25 text-lg text-muted custom-description">You must hold 50.000 ALY to audit
            </p>
        </div>
        <a-form id="components-step-1" class="step-1">
            <a-form-item label="Name Token *">
                <template slot="extra">
                    <a-tooltip placement="topLeft" title="Please input your Name Token !">
                        <a-icon type="info-circle" style="color: #c50b0b" />
                        <span class="icon-text ml-3">Please input your Name Token !</span>
                    </a-tooltip>
                </template>
                <a-input v-model="step1Data.name_token" @blur="updateData"
                    v-decorator="['name_token', { rules: [{ required: true, message: 'Please input your Name Token !' }] }]" />
            </a-form-item>
            <a-form-item label="Symbol *">
                <template slot="extra">
                    <a-tooltip placement="topLeft" title="Please input your Symbol!">
                        <a-icon type="info-circle" style="color: #c50b0b" />
                        <span class="icon-text ml-3">Please input your Symbol!</span>
                    </a-tooltip>
                </template>
                <a-input v-model="step1Data.symbol" @blur="updateData"
                    v-decorator="['symbol', { rules: [{ required: true, message: 'Please input your Symbol!' }] }]" />
            </a-form-item>
            <a-form-item label="Contract Address (CA) *">
                <template slot="extra">
                    <a-tooltip placement="topLeft" title="Please input your Contract Address (CA) !">
                        <a-icon type="info-circle" style="color: #c50b0b" />
                        <span class="icon-text ml-3">Please input your Contract Address (CA) !</span>
                    </a-tooltip>
                </template>
                <a-input v-model="step1Data.ca" @blur="updateData"
                    v-decorator="['ca', { rules: [{ required: true, message: 'Please input your Contract Address (CA) !' }] }]" />
            </a-form-item>
            <a-form-item label="Website">
                <a-input v-model="step1Data.website" @blur="updateData"
                    v-decorator="['website', { rules: [{ required: true, message: 'Please input your Website !' }] }]" />
            </a-form-item>
            <a-form-item label="Telegram">
                <a-input v-model="step1Data.telegram" @blur="updateData"
                    v-decorator="['telegram', { rules: [{ required: true, message: 'Please input your Telegram !' }] }]" />
            </a-form-item>


        </a-form>
    </div>
</template>
  
<script>
export default {
    data() {
        return {
            step1Data: {
                name_token: '',
                symbol: '',
                ca: '',
                website: '',
                telegram: '',
            }
        };
    },
    watch: {
        step1Data(newVal) {
            this.$emit('updateData', newVal);
        }
    },
    methods: {
        updateData() {
            this.$emit('updateData', this.step1Data);
        }
    }

};
</script>

<style scoped>
.icon-text {
    position: relative;
    margin-left: 5px;
}
</style>